import FormCard from "../form-card/FormCard";
import SignIn from "../signIn/SignIn";
import Register from "../register/Register";

const Members = (props) => {
  return (
    <div>
      <FormCard>
        <SignIn />
      </FormCard>
    </div>
  );
};

export default Members;
