import React from "react";
import styles from "./ContactUs.module.css";
import * as mdb from "mdb-ui-kit";
import { Input } from "mdb-ui-kit";

const ContactUs = () => {
  return (
    <div>
      <section className={`intro ${styles.intro}`}>
        <div className="mask d-flex align-items-center h-100">
          <div className="container">
            <div className="row justify-content-center my-5">
              <div className={`col-12 col-lg-10 col-xl-10 `}>
                <div className={`card ${styles.card} `}>
                  <div className="card-body m-3 p-4 p-md-5">
                    <h1 className={`mb-4 display-5 pb-2 ${styles.title}`}>
                      Contact Us
                    </h1>
                    <form
                      name="contactUs"
                      method="POST"
                      netlify
                      netlify-honeypot="bot-field"
                      data-netlify="true"
                      onSubmit="submit"
                    >
                      <input type="hidden" name="form-name" value="contactUs" />
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="form-outline">
                            <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              className={`form-control ${styles.input}`}
                            />
                            <label className="form-label" htmlFor="firstName">
                              First Name
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="form-outline">
                            <input
                              type="text"
                              id="lastName"
                              name="lastName"
                              className={`form-control ${styles.input}`}
                            />
                            <label className="form-label" htmlFor="lastName">
                              Last Name
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="form-outline">
                            <input
                              type="email"
                              id="emailAddress"
                              name="email"
                              className={`form-control ${styles.input}`}
                            />
                            <label
                              className="form-label"
                              htmlFor="emailAddress"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="form-outline">
                            <input
                              type="tel"
                              id="phoneNumber"
                              name="phoneNumber"
                              className={`form-control ${styles.input}`}
                            />
                            <label className="form-label" htmlFor="phoneNumber">
                              Phone Number
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="mt-4">
                            <input
                              className={`btn btn-lg btn-block ${styles.submitButton}`}
                              type="submit"
                              value="Submit"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
