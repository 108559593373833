import React from "react";

const Locations = () => {
  return (
    <div>
      <p>locations</p>
    </div>
  );
};

export default Locations;
