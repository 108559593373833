import React from "react";
import styles from "./SignIn.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../utils/firebase";

const SignIn = () => {
  const googleProvider = new GoogleAuthProvider();
  const GoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log(result.user);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <h1 className={`mb-4 display-5 pb-2 ${styles.title}`}>Sign In</h1>

      <form action="">
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="form-outline">
              <input
                type="email"
                id="emailAddress"
                className={`form-control ${styles.input}`}
              />
              <label className="form-label" htmlFor="emailAddress">
                Email
              </label>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-outline">
              <input
                type=""
                id="password"
                className={`form-control ${styles.input}`}
              />
              <label className="form-label" htmlFor="password">
                Password
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-4">
              <input
                className={`btn btn-lg btn-block ${styles.submitButton}`}
                type="submit"
                value="Submit"
              />
            </div>
          </div>
        </div>
      </form>

      <div
        className={`d-flex justify-content-center p-4 gap-2 mt-5 ${styles.providerContainer} `}
      >
        <button
          onClick={GoogleLogin}
          className={`p-2 ${styles.providerSignInBtn}`}
        >
          <FontAwesomeIcon className={`px-2 ${styles.icon}`} icon={faGoogle} />
          Sign in with Google
        </button>
        <button className={`p-2 ${styles.providerSignInBtn}`}>
          <FontAwesomeIcon
            className={`px-2 ${styles.icon}`}
            icon={faFacebook}
          />
          Sign in with Facebook
        </button>
      </div>
    </div>
  );
};

export default SignIn;
